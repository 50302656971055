import React, { useEffect, useState } from 'react';

const VisitorCounter = () => {
  const [count, setCount] = useState(0);

  const binId = '6780c168e41b4d34e4744681';
  const apiKey = '$2a$10$.rAZskLd1w9utWoeuemOtunHKkNc1vB4P75IIs/mvBO6CnNwyJ/hy'; // Found in your JSONBin dashboard

  const fetchVisitorCount = async () => {
    try {
      const response = await fetch(`https://api.jsonbin.io/v3/b/${binId}`, {
        method: 'GET',
        headers: {
          'X-Master-Key': apiKey,
        },
      });
      const data = await response.json();
      const updatedCount = data.record.visitorCount + 1;

      // Update the count in JSONBin
      await fetch(`https://api.jsonbin.io/v3/b/${binId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-Master-Key': apiKey,
        },
        body: JSON.stringify({ visitorCount: updatedCount }),
      });

      setCount(updatedCount);
    } catch (error) {
      console.error('Error fetching/updating visitor count:', error);
    }
  };

  useEffect(() => {
    fetchVisitorCount();
  }, []);

  return (
    <div>
      <h1>Visitor Count: {count}</h1>
    </div>
  );
};

export default VisitorCounter;


